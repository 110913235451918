import React, { useState } from "react";
import { TableCell, TableRow, Menu, MenuItem } from "@mui/material";
import { tableStyles } from "../tableStyles";
import { useTheme } from "@emotion/react";
import { useSettings } from "../../Layout/SettingsContext";
import { colorForProgress } from "../utils";
import { unitRangeToDisplayPercentage, codioGrade } from "../utils";

function isFromToday(timestampStr) {
  const timestamp = new Date(timestampStr).getTime();
  const now = new Date();
  const todayStart = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    0,
    0,
    0,
    0
  ).getTime();

  return timestamp >= todayStart;
}

function StudentRow({ student, index, exerciseHeaders, studentInfo }) {
  const theme = useTheme();
  const style = tableStyles(theme);
  const { collapseTasks } = useSettings();
  const exercisesDoneByStudentIDs = Object.keys(student.exercises);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenHubSpot = () => {
    const hubSpotURL = `https://app.hubspot.com/contacts/9484219/contact/${student.hs_contact_id}`;
    window.open(hubSpotURL, "_blank");
    handleClose();
  };

  const handleOpenSlack = () => {
    const hubSpotURL = `slack://user?team=T01QHL5D7HV&id=${student.slack_id}`;
    window.open(hubSpotURL, "_blank");
    handleClose();
  };

  // something bad happened
  if (!studentInfo) {
    console.log("Didn't find contact on HubSpot");
    console.log(student);
    return <></>;
  }

  return (
    <TableRow key={student.id}>
      <TableCell>{index + 1}</TableCell>
      <TableCell
        style={{
          ...style.cell,
          ...style.nameCell,
        }}
      >
        {studentInfo.mentor}
      </TableCell>
      <TableCell
        style={{
          ...style.cell,
          ...style.headerCell,
          ...style.stickyCell,
          ...style.nameCell,
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        {studentInfo.is_bg_enrolled && "🌟 "}
        {student.first_name} {student.last_name}
        <span style={{ fontWeight: 300 }}>
          {studentInfo.roles.length > 0 && ` (MS Employee)`}
        </span>
        <br />({student.doneCount} done)
      </TableCell>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleOpenSlack}>Slack</MenuItem>
        <MenuItem onClick={handleOpenHubSpot}>HubSpot</MenuItem>
      </Menu>

      {exerciseHeaders.map((header) => {
        let cellStyle = { ...style.cell };
        let exerciseProgress = student.exercises[header.exerciseId];
        const isCodioProject = exerciseProgress && "grade" in exerciseProgress;

        if (collapseTasks && exerciseProgress === undefined) {
          const doneExercisesInLesson = header.allExercisesInLesson.filter(
            (exerciseId) => exercisesDoneByStudentIDs.includes(exerciseId)
          );
          const numberOfDoneExercisesInLesson = doneExercisesInLesson.length;
          const anyDoneToday = doneExercisesInLesson.some(
            (exerciseId) =>
              student.exercises[exerciseId] &&
              isFromToday(student.exercises[exerciseId].updated_at)
          );
          exerciseProgress = {
            passed:
              numberOfDoneExercisesInLesson ===
              header.allExercisesInLesson.length,
            numberOfDoneExercisesInLesson,
            totalInLesson: header.allExercisesInLesson.length,
            anyDoneToday,
          };
        }

        if (exerciseProgress) {
          let backgroundColor = exerciseProgress.passed
            ? style.doneCell.backgroundColor
            : style.redoCell.backgroundColor;

          let percentage = undefined;
          if (collapseTasks) {
            percentage =
              exerciseProgress.numberOfDoneExercisesInLesson /
              exerciseProgress.totalInLesson;
          }

          // for try hack me
          if (exerciseProgress.progress) {
            percentage = exerciseProgress.progress;
          }

          if (percentage !== undefined) {
            backgroundColor = colorForProgress(percentage);
          }

          cellStyle = { ...cellStyle, backgroundColor };
        }
        if (
          exerciseProgress &&
          (isFromToday(exerciseProgress.updated_at) ||
            exerciseProgress.anyDoneToday)
        ) {
          cellStyle = { ...cellStyle, ...style.recentSubmission };
        }

        const is_quiz_cell =
          exerciseProgress &&
          Object.keys(exerciseProgress).includes("highest_grade");

        if (is_quiz_cell) {
          cellStyle = {
            ...cellStyle,
            fontSize: "13px",
            fontWeight: "600",
            backgroundColor: colorForProgress(
              exerciseProgress.highest_grade / 100
            ),
          };
        }

        if (isCodioProject) {
          cellStyle = cellForCodioProject(exerciseProgress, cellStyle);
        }
        const has_percentage_progress =
          exerciseProgress &&
          Object.keys(exerciseProgress).includes("progress");

        const textForCell = () => {
          if (is_quiz_cell) {
            return `Grade: ` + exerciseProgress.highest_grade;
          } else if (has_percentage_progress) {
            return unitRangeToDisplayPercentage(exerciseProgress.progress);
          } else if (collapseTasks && !isCodioProject) {
            return `${unitRangeToDisplayPercentage(
              exerciseProgress.numberOfDoneExercisesInLesson /
                exerciseProgress.totalInLesson
            )}`;
          } else if (isCodioProject) {
            return codioGrade(exerciseProgress.grade ?? 0);
          } else if (exerciseProgress) {
            return "V" + exerciseProgress.submission_number;
          } else {
            return null;
          }
        };

        return (
          <TableCell
            align="center"
            key={header.sprintId + header.lessonName + header.exerciseId}
            style={cellStyle}
          >
            {textForCell()}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

export function StudentRows({
  studentProgress,
  filteredStudents,
  exerciseHeaders,
  studentsInfo,
}) {
  const { collapseTasks } = useSettings();
  // filter the progess by the mentors
  let filteredProgress = studentProgress;
  if (filteredStudents.length > 0) {
    filteredProgress = Object.fromEntries(
      Object.entries(studentProgress).filter(([student_id, student]) =>
        filteredStudents.includes(student.email)
      )
    );
  }

  // sort students by the number of done exercise
  const sortedStudents = Object.entries(filteredProgress)
    .map(([id, student]) => {
      // Don't count things in the progress that aren't in the current view
      let doneCount = 0;
      if (collapseTasks) {
        const filteredExercises = Object.fromEntries(
          Object.entries(student.exercises).filter(([exercise_id, exercise]) =>
            exerciseHeaders
              .flatMap((e) => e.allExercisesInLesson)
              .includes(exercise_id)
          )
        );
        doneCount = Object.values(filteredExercises).filter(
          (e) => e.passed
        ).length;
      } else {
        const filteredExercises = Object.fromEntries(
          Object.entries(student.exercises).filter(([exercise_id, exercise]) =>
            exerciseHeaders.map((e) => e.exerciseId).includes(exercise_id)
          )
        );
        doneCount = Object.values(filteredExercises).filter(
          (e) => e.passed
        ).length;
      }
      return {
        ...student,
        id,
        doneCount,
      };
    })
    .sort((a, b) => b.doneCount - a.doneCount); // Sort by done count, descending order

  return (
    <>
      {sortedStudents.map((student, index) => (
        <StudentRow
          key={index}
          student={student}
          index={index}
          exerciseHeaders={exerciseHeaders}
          studentInfo={studentsInfo[student.id]}
        />
      ))}
    </>
  );
}

function cellForCodioProject(exerciseProgress, style) {
  const cellStyle = {
    ...style,
    fontSize: "13px",
    fontWeight: "600",
    backgroundColor: colorForProgress(exerciseProgress.grade ?? 0),
  };
  return cellStyle;
}
