// components/Layout/Layout.jsx or App.jsx

import React from 'react';
import Navbar from './Navbar';

function Layout({ children, setDarkMode, setOffering, setCollapseTasks }) {
  return (
    <div>
      <Navbar setDarkMode={setDarkMode} setOffering={setOffering} setCollapseTasks={setCollapseTasks} />
      <main>
        {children}
      </main>
    </div>
  );
}

export default Layout;
