import React from "react";
import { TableCell, TableRow, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tableStyles } from "../tableStyles";
import { colorForName } from "../utils";
import { useSettings } from "../../Layout/SettingsContext";

const COLLAPSED_WIDTH = "95px";

function SprintHeaders({ sprintHeaders }) {
  const theme = useTheme();
  const style = tableStyles(theme);
  const headerComponents = sprintHeaders.map((header) => {
    const background = colorForName(header.sprintName, 0.6);
    return (
      <TableCell
        align="center"
        colSpan={header.colSpan}
        key={header.sprintId}
        style={{
          ...style.cell,
          ...style.headerCell,
          ...style.stickySprintHeader,
          background,
        }}
        title={header.sprintName}
      >
        {header.sprintName}
      </TableCell>
    );
  });

  return (
    <TableRow>
      <TableCell
        style={{
          ...style.cell,
          ...style.headerCell,
          ...style.stickySprintHeader,
        }}
        rowSpan={4}
      >
        #
      </TableCell>
      <TableCell
        style={{
          ...style.cell,
          ...style.headerCell,
          ...style.stickySprintHeader,
        }}
        rowSpan={4}
      >
        Mentor
      </TableCell>
      <TableCell
        style={{
          ...style.cell,
          ...style.headerCell,
          ...style.stickySprintHeader,
        }}
      >
        Sprint
      </TableCell>
      {headerComponents}
    </TableRow>
  );
}

function LessonHeaders({ lessonHeaders }) {
  const theme = useTheme();
  const style = tableStyles(theme);
  const { collapseTasks, darkMode } = useSettings();
  const maxWidth = collapseTasks ? COLLAPSED_WIDTH : style.cell.maxWidth;
  const maxHeight = collapseTasks ? "10px" : style.cell.height;
  const whiteSpace = collapseTasks
    ? "wrap"
    : style.stickyLessonHeader.whiteSpace;
  return (
    <TableRow>
      <TableCell
        style={{
          ...style.cell,
          ...style.headerCell,
          ...style.stickyLessonHeader,
          maxHeight,
        }}
      >
        Lesson
      </TableCell>

      {lessonHeaders.map((header, index) => {
        const defaultBGColor = darkMode ? "black" : "white";
        let backgroundColor = collapseTasks
          ? defaultBGColor
          : colorForName(header.lesson, 0.6);
        if (header.isAdvanced) {
          backgroundColor = "#aaaaaa";
        }
        if (header.type === "project") {
          //give a backgroundColor to the project lessons something distinct and pleasant and
          backgroundColor = "#bf80cf";
        }
        return (
          <TableCell
            align="center"
            colSpan={header.colSpan}
            key={index}
            style={{
              ...style.cell,
              ...style.headerCell,
              ...style.stickyLessonHeader,
              backgroundColor,
              border:
                header.type === "test" ? "2px solid red" : style.cell.border,
              maxWidth,
              height: maxHeight,
              maxHeight,
              whiteSpace,
            }}
            title={header.lesson}
          >
            {collapseTasks ? (
              <Typography
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 3,
                }}
                variant="body2"
              >
                {header.lesson}
              </Typography>
            ) : (
              header.lesson
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

function PageHeaders({ pageHeaders }) {
  const theme = useTheme();
  const style = tableStyles(theme);
  return (
    <TableRow>
      <TableCell
        style={{
          ...style.cell,
          ...style.headerCell,
          ...style.stickyPageHeader,
        }}
      >
        Chapter
      </TableCell>
      {pageHeaders.map((header, index) => (
        <TableCell
          align="center"
          colSpan={header.colSpan}
          key={index}
          style={{
            ...style.cell,
            ...style.headerCell,
            ...style.stickyPageHeader,
            backgroundColor: colorForName(header.lessonName, 0.7),
          }}
          title={header.page}
        >
          {header.page}
        </TableCell>
      ))}
    </TableRow>
  );
}

function ExerciseHeaders({ exerciseHeaders }) {
  const theme = useTheme();
  const style = tableStyles(theme);

  return (
    <TableRow>
      <TableCell
        style={{
          ...style.cell,
          ...style.headerCell,
          ...style.stickyExerciseHeader,
        }}
      >
        Exercise
      </TableCell>
      {exerciseHeaders.map((header, index) => (
        <TableCell
          align="center"
          key={index}
          style={{
            ...style.cell,
            ...style.headerCell,
            ...style.stickyExerciseHeader,
            backgroundColor: colorForName(header.lessonName, 0.8),
          }}
          title={header.name}
        >
          {header.name}
        </TableCell>
      ))}
    </TableRow>
  );
}

export function Headers({
  sprintHeaders,
  lessonHeaders,
  pageHeaders,
  exerciseHeaders,
}) {
  const { collapseTasks } = useSettings();
  return (
    <>
      <SprintHeaders sprintHeaders={sprintHeaders} />
      <LessonHeaders lessonHeaders={lessonHeaders} />
      {collapseTasks ? null : <PageHeaders pageHeaders={pageHeaders} />}
      {collapseTasks ? null : (
        <ExerciseHeaders exerciseHeaders={exerciseHeaders} />
      )}
    </>
  );
}
