import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { useUser } from "../../Auth/UserContext";
import { Box, Button, ListItemText, MenuItem, Menu } from "@mui/material";
import { useSettings } from "../../Layout/SettingsContext";

async function fetchOfferings(authenticatedFetch) {
  const apiHost = process.env.REACT_APP_HOST || "";

  return authenticatedFetch(`${apiHost}/api/bg/offerings`).then((res) =>
    res.json()
  );
}

export function OfferingSelector({ setOffering }) {
  const onOfferingChange = (offering) => {
    setOffering(offering);
  };
  const { isLoadingAuth, user, authenticatedFetch } = useUser();

  const [open, setOpen] = useState(false);
  const [offerings, setOfferings] = useState([]);
  const [loading, setLoading] = useState(true);
  const { selectedOffering } = useSettings();

  const anchorRef = useRef(null);

  useEffect(() => {
    if (isLoadingAuth || !user) {
      return;
    }
    setLoading(true);
    fetchOfferings(authenticatedFetch)
      .then((data) =>
        data.map((offering) => {
          return {
            id: offering.id,
            displayName: offeringToDisplayName(offering),
            startDate: offering.start_date,
            description: offering.description,
          };
        })
      )
      .then((data) => {
        data.sort((a, b) => new Date(a.startDate) > new Date(b.startDate) ? -1 : 1);
        setOfferings(data);
        setLoading(false);
      });
  }, [authenticatedFetch, isLoadingAuth, user]);

  return (
    <Box>
      <div ref={anchorRef} style={{ width: "200px"}} >
        <OpenOfferingSelector
          onClick={() => {
            setOpen(true);
          }}
          disabled={loading}
          title={selectedOffering.displayName}
        />
      </div>
      <Menu
        anchorEl={anchorRef.current}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {offerings.map((offering) => (
          <MenuItem
            key={offering.id}
            value={offering.displayName}
            onClick={() => {
              setOpen(false);
              onOfferingChange(offering);
            }}
            sx={{
              backgroundColor: bgColorForStartDate(offering.startDate),
              border: selectedOffering.id === offering.id ? "1px solid black" : "none"
            }}
          >
            <ListItemText
              primary={offering.displayName + ` (${offering.description})`}
              primaryTypographyProps={{
                fontSize: "14px",
                fontWeight: selectedOffering.id === offering.id ? 600 : 400,
              }}
            />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

function OpenOfferingSelector({ onClick, disabled, title }) {
  const displayTitle = prettifyStr(title) || "Select Program";
  return (
    <Button
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      disabled={disabled}
      variant="outlined"
      color="inherit"
      sx={{
        textTransform: "none",
      }}
    >
      {displayTitle}
    </Button>
  );
}

function offeringToDisplayName(offering) {
  const startDate = new Date(offering["start_date"]);
  ///extract month as a string in london time
  const month = startDate.toLocaleString("en-GB", {
    month: "long",
    timeZone: "Europe/London",
  });
  return `${domainName(offering["domain"])} ${month}`;
}

function domainName(domain) {
  switch (domain) {
    case "DATA_SCIENCE":
      return "Data";
    case "WEB":
      return "Software";
    case "CYBER":
      return "Cyber";
    case 'COMPASS':
      return 'Orientation';
    default:
      return prettifyStr(domain);
  }
}

function prettifyStr(str) {
  // replace _ with space and capitalize first letter of each word
  return str.toLowerCase().replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase());
}

function bgColorForStartDate(str) {
  const startDate = new Date(str);
  
  const month = startDate.getMonth();
  return `hsl(${(month * 30) % 360}, 100%, 90%)`;
}