import { Container, Typography, Snackbar } from '@mui/material';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import GoogleButton from 'react-google-button';
import app from '../Auth/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Make sure you have axios installed.
import { useState } from 'react';

const apiHost = process.env.REACT_APP_HOST || "";

function LoginPage() {
    const provider = new GoogleAuthProvider();
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const handleLogin = async () => {
        const auth = getAuth(app);
        signInWithPopup(auth, provider)
            .then(async (result) => {
                const token = await result.user.getIdToken();

                // Send the token to your backend for validation
                axios.get(`${apiHost}/api/auth`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }).then((response) => {
                    if (response.data.isApproved) { // Assuming your server returns { isApproved: true/false }
                        navigate("/");
                    }
                }).catch((error) => {
                        if (error.response.status === 403) {
                            setSnackbarMessage("User is not authorized");
                            setSnackbarOpen(true);
                            signOut(auth); // Sign out from Firebase if not approved

                        } else {
                            setSnackbarMessage("An error has occured");
                            setSnackbarOpen(true);
                            signOut(auth);
                            console.error("Error during server validation:", error);
                        }
                    });
            })
            .catch((error) => {
                setSnackbarMessage("Error during sign-in");
                setSnackbarOpen(true);
                console.error("Error during sign-in:", error);
            });
    };

    return (
        <Container component="main" maxWidth="xs" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '20%', height: '100vh' }}>
            <Typography variant="h5" gutterBottom sx={{ fontSize: 38, fontWeight: "bold" }}>Dashboard Login</Typography>
            <GoogleButton
                onClick={handleLogin}
                style={{ marginTop: '35px' }}
            />
            <Snackbar
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                autoHideDuration={6000}
            />
        </Container>
    );
}

export default LoginPage;
