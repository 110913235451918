// components/Navbar/Navbar.jsx

import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Typography,
  Divider,
  Switch,
  FormControlLabel,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { useUser } from "../Auth/UserContext";
import { getAuth, signOut } from "firebase/auth";
import { OfferingSelector } from "../ProgressTable/components/OfferingSelector";
import { useSettings } from "../Layout/SettingsContext";

function Navbar({ setDarkMode, setOffering, setCollapseTasks }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { user } = useUser();
  const auth = getAuth();
  const { darkMode, collapseTasks } = useSettings();

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
      handleClose();
    });
  };

  return (
    <AppBar position="static">
      <Toolbar sx={{ bgcolor: "#009B50" }}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleOpen}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {user && (
            <div>
              <MenuItem onClick={handleLogout}>
                <Avatar
                  src={user.photoURL}
                  alt={user.displayName}
                  sx={{ height: 40, width: 40 }}
                />
                <Typography sx={{ paddingLeft: 2 }}>Logout</Typography>
              </MenuItem>
              <Divider />
              <Link to="/" style={{ textDecoration: "none" }}>
                <MenuItem>
                  <Typography>The Matrix</Typography>
                </MenuItem>
              </Link>
              <Link to="/livelessons" style={{ textDecoration: "none" }}>
                <MenuItem>
                  <Typography>Live Sessions Report</Typography>
                </MenuItem>
              </Link>
              <Link to="/motivation" style={{ textDecoration: "none" }}>
                <MenuItem>
                  <Typography>Motivation Report</Typography>
                </MenuItem>
              </Link>
            </div>
          )}
        </Menu>
        <Box minWidth="400px" paddingLeft="20px">
          <OfferingSelector setOffering={setOffering} />
        </Box>
        <Box width="100%" display="flex" justifyContent="end">
          <FormControlLabel
            control={
              <Switch
                checked={collapseTasks}
                onChange={(e) => setCollapseTasks(e.target.checked)}
                aria-label="Dark Mode"
              />
            }
            label="Collapse Tasks"
          />
        </Box>
        <Box width="100%" display="flex" justifyContent="end">
          <FormControlLabel
            control={
              <Switch
                checked={darkMode}
                onChange={(e) => setDarkMode(e.target.checked)}
                aria-label="Dark Mode"
              />
            }
            label="Dark Mode"
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
