import { createContext, useContext } from "react";

const SettingsContext = createContext({
  selectedOffering: {
    id: 438,
    displayName: "DA October",
  },
  darkMode: false,
  collapseTasks: false,
});

export function useSettings() {
  return useContext(SettingsContext);
}

export default SettingsContext;
