import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import LoginPage from "./components/LoginPage/LoginPage";
import ProgressTable from "./components/ProgressTable/ProgressTable";
import Layout from "./components/Layout/Layout";
import AuthProvider from "./components/Auth/AuthProvider";
import PrivateRoute from "./components/Auth/PrivateRoute";
import LiveLessons from "./components/LiveLessons/LiveLessons";
import MotivationReport from "./components/Motivation/MotivationReport";
import SettingsContext from "./components/Layout/SettingsContext";

const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
  background: {
    default: "#ffffff", // light background color
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#333333", // dark background color
    },
  },
});

function App() {
  const storageOffering = window.localStorage.getItem("offering");
  const defaultOffering = {
    id: 438,
    displayName: "DA October",
  };
  const [darkMode, setDarkMode] = useState(
    window.localStorage.getItem("darkMode") === "true"
  );
  const [offering, setOffering] = useState(
    storageOffering ? JSON.parse(storageOffering) : defaultOffering
  );
  const [collapseTasks, setCollapseTasks] = useState(
    window.localStorage.getItem("collapseTasks") === "true"
  );

  const changeDarkMode = (darkMode) => {
    window.localStorage.setItem("darkMode", darkMode);
    setDarkMode(darkMode);
  };

  const changeOffering = (offering) => {
    window.localStorage.setItem("offering", JSON.stringify(offering));
    setOffering(offering);
  };

  const changeCollapseTasks = (collapseTasks) => {
    window.localStorage.setItem("collapseTasks", collapseTasks);
    setCollapseTasks(collapseTasks);
  };

  return (
    <div>
      <SettingsContext.Provider
        value={{ darkMode, selectedOffering: offering, collapseTasks }}
      >
        <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
          <AuthProvider>
            <Router>
              <Layout
                setDarkMode={changeDarkMode}
                setOffering={changeOffering}
                setCollapseTasks={changeCollapseTasks}
              >
                <Routes>
                  <Route path="/" element={<PrivateRoute />}>
                    <Route index element={<ProgressTable />} />
                    <Route path="/livelessons" element={<LiveLessons />} />
                    <Route path="/motivation" element={<MotivationReport />} />
                  </Route>
                  <Route path="/login" element={<LoginPage />} />
                </Routes>
              </Layout>
            </Router>
          </AuthProvider>
        </ThemeProvider>
      </SettingsContext.Provider>
    </div>
  );
}

export default App;
