export const tableStyles = (theme) => ({
  cell: {
    border: `1px solid ${theme.palette.divider}`,
    padding: "8px 16px",
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "0.9em",
    maxHeight: "20px",
    height: "20px",
    textAlign: "center",
    whiteSpace: "wrap",
    overflow: "hidden",
    width: "30px",
    maxWidth: "30px",
  },
  headerCell: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 600,
    backgroundColor: theme.palette.background.default,
  },
  stickyCell: {
    position: "sticky",
    left: 0, // Stick to the left side
    zIndex: 2, // Ensures the cell appears above the other cells
    backgroundColor: theme.palette.background.default,
  },
  stickySprintHeader: {
    position: "sticky",
    top: 0,
    zIndex: 10, // Ensure the header is above other content
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxHeight: "40px",
  },
  stickyLessonHeader: {
    position: "sticky",
    top: "41px", // Adjust according to the height of your headers
    zIndex: 10,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxHeight: "40px",
  },
  stickyPageHeader: {
    position: "sticky",
    top: "82px",
    zIndex: 10,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxHeight: "40px",
  },
  stickyExerciseHeader: {
    position: "sticky",
    top: "123px",
    zIndex: 10,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxHeight: "40px",
  },
  nameCell: {
    width: "150px",
    maxWidth: "150px", // Set your desired width here
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  doneCell: {
    backgroundColor: theme.palette.mode === "dark" ? "#109710" : "#acffac",
  },
  redoCell: {
    backgroundColor: theme.palette.mode === "dark" ? "#b39637" : "#fff8ac",
  },
  recentSubmission: {
    color: "red",
  },
});
