import React, { useEffect, useState } from "react";
import Heatmap from "./Heatmap";
import { useUser } from "../Auth/UserContext";
import { Box, CircularProgress } from "@mui/material";
import { useSettings } from "../Layout/SettingsContext";

const MotivationReport = () => {
  const [loading, setLoading] = useState(true);
  const { authenticatedFetch } = useUser();
  const [studentsData, setStudentsData] = useState({});
  const { selectedOffering } = useSettings();

  useEffect(() => {
    // Fetching data from the API endpoint
    const apiHost = process.env.REACT_APP_HOST || "";
    setLoading(true);
    authenticatedFetch(
      `${apiHost}/api/motivation?offeringId=${selectedOffering.id}`
    )
      .then((response) => {
        if (!response) {
          return {};
        }
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setStudentsData(data);
      })
      .catch((error) =>
        console.error("There was a problem fetching the data:", error)
      )
      .finally(() => {
        setLoading(false);
      });
  }, [authenticatedFetch, selectedOffering.id]);

  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          zIndex: loading ? 100 : -1,
          opacity: loading ? 100 : 0,
          position: "absolute",
          backgroundColor: "white",
          transition: "all 0.5s ease-in-out",
        }}
      >
        <CircularProgress />
      </Box>

      {Object.keys(studentsData).length ? (
        <Heatmap data={studentsData} />
      ) : null}
    </>
  );
};

export default MotivationReport;
