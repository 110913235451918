import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import {
  Box,
  Table,
  TableBody,
  TableHead,
  Paper,
  TableContainer,
} from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";

import { StudentRows } from "./components/StudentRows";
import { Headers } from "./components/Headers";
import { MentorFilter } from "./components/MentorFilter";
import { useUser } from "../Auth/UserContext";
import { SprintFilter } from "./components/SprintFilter";
import { useSettings } from "../Layout/SettingsContext";

const TABLE_RELOAD_TIME = 1000 * 60 * 5; // every 5 minutes seconds

async function fetchData(offering, authenticatedFetch) {
  const apiHost = process.env.REACT_APP_HOST || "";

  const query = `?offeringId=${offering}`;

  // Start all fetches concurrently
  const sprintsPromise = authenticatedFetch(
    `${apiHost}/api/sprints${query}`
  ).then((res) => res.json());
  const progressPromise = authenticatedFetch(
    `${apiHost}/api/progress${query}`
  ).then((res) => res.json());
  const studentinfoPromise = authenticatedFetch(
    `${apiHost}/api/studentinfo${query}`
  ).then((res) => res.json());

  // Await all fetches to complete
  const [sprintsData, progressData, studentinfoData] = await Promise.all([
    sprintsPromise,
    progressPromise,
    studentinfoPromise,
  ]);

  return { sprintsData, progressData, studentinfoData };
}

function filterSyllabusSprints(sprints, selectedSprintIds) {
  if (selectedSprintIds.length === 0) {
    return sprints;
  }
  return sprints.filter((sprint) => selectedSprintIds.includes(sprint.id));
}

function getTableHeadersWithColSpan(selectedSprints, collapseTasks) {
  const sprintHeaders = [];
  const lessonHeaders = [];
  const pageHeaders = [];
  const exerciseHeaders = [];

  selectedSprints.forEach((sprint) => {
    let sprintExercisesCount = 0;

    const advancedLessons = sprint.lessons.filter(
      (lesson) => lesson.isAdvanced
    );
    const normalLessons = sprint.lessons.filter((lesson) => !lesson.isAdvanced);
    const sortedLessons = [...normalLessons, ...advancedLessons];

    sortedLessons.forEach((lesson) => {
      let lessonExercisesCount = 0;
      if (collapseTasks) {
        pageHeaders.push({
          page: lesson.name,
          lessonName: lesson.name,
          sprintId: sprint.id,
          colSpan: 1,
        });
        exerciseHeaders.push({
          name: `${lesson.name}`,
          exerciseId: lesson.id,
          lessonName: lesson.name,
          sprintId: sprint.id,
          allExercisesInLesson: lesson.pages.flatMap((page) => page.exercises),
        });
        lessonExercisesCount = 1;
        sprintExercisesCount += 1;
      } else {
        lesson.pages.forEach((page) => {
          const pageExercisesCount = page.exercises.length;
          lessonExercisesCount += pageExercisesCount;
          sprintExercisesCount += pageExercisesCount;

          pageHeaders.push({
            page: page.name,
            lessonName: lesson.name,
            colSpan: pageExercisesCount,
          });

          // Local index for exercises within the current sprint
          let localExerciseIndex = 1;
          page.exercises.forEach((exercise) => {
            exerciseHeaders.push({
              name: `${localExerciseIndex}`,
              exerciseId: exercise,
              lessonName: lesson.name,
            });
            localExerciseIndex++;
          });
        });
      }

      lessonHeaders.push({
        lesson: lesson.name,
        type: lesson.type,
        colSpan: lessonExercisesCount,
        isAdvanced: lesson.isAdvanced,
      });
    });

    sprintHeaders.push({
      sprintName: sprint.name,
      sprintId: sprint.id,
      colSpan: sprintExercisesCount,
    });
  });

  return { sprintHeaders, lessonHeaders, pageHeaders, exerciseHeaders };
}

function ProgressTable() {
  const { isLoadingAuth, user, authenticatedFetch } = useUser();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const sprints = data.sprintsData ?? [];
  const studentProgress = data.progressData ?? {};
  const studentsInfo = data.studentinfoData ?? {};

  const [mentors, setMentors] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [filteredSprints, setFilteredSprints] = useState([]);
  const { selectedOffering, collapseTasks } = useSettings();

  useEffect(() => {
    if (isLoadingAuth || !user) {
      // wait until the auth is finished
      return;
    }

    async function loadData() {
      setLoading(true);
      fetchData(selectedOffering.id, authenticatedFetch).then((data) => {
        setData(data);
        setLoading(false);
      });
    }

    loadData();

    // Set an interval to fetch data every 60 seconds (60000 milliseconds)
    const interval = setInterval(() => {
      loadData();
    }, TABLE_RELOAD_TIME);

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [isLoadingAuth, user, authenticatedFetch, selectedOffering]);

  // mentors
  useEffect(() => {
    const studentsInfo = data.studentinfoData ?? {};
    setMentors([
      ...new Set(Object.values(studentsInfo).map((student) => student.mentor)),
    ]);
  }, [data]);

  const filteredSyllabusSprints = filterSyllabusSprints(
    sprints,
    filteredSprints
  );
  const { sprintHeaders, lessonHeaders, pageHeaders, exerciseHeaders } =
    getTableHeadersWithColSpan(filteredSyllabusSprints, collapseTasks);

  return (
    <div>
      <Helmet>
        <title>Student Dashboard</title>
      </Helmet>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          zIndex: loading ? 100 : -1,
          opacity: loading ? 100 : 0,
          position: "absolute",
          backgroundColor: "white",
          transition: "all 0.5s ease-in-out",
        }}
      >
        <CircularProgress />
      </Box>
      <SprintFilter sprints={sprints} setFilteredSprints={setFilteredSprints} />
      <MentorFilter
        mentors={mentors}
        studentsInfo={studentsInfo}
        setFilteredStudents={setFilteredStudents}
      />
      <Paper elevation={3} style={{ borderRadius: "8px" }}>
        <TableContainer style={{ maxHeight: "75vh" }}>
          {" "}
          {/* Set a max height here */}
          <Table>
            <TableHead>
              <Headers
                sprintHeaders={sprintHeaders}
                lessonHeaders={lessonHeaders}
                pageHeaders={pageHeaders}
                exerciseHeaders={exerciseHeaders}
              />
            </TableHead>
            <TableBody>
              <StudentRows
                studentProgress={studentProgress}
                studentsInfo={studentsInfo}
                filteredStudents={filteredStudents}
                exerciseHeaders={exerciseHeaders}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default ProgressTable;
