import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';


export function MentorFilter({ mentors, studentsInfo, setFilteredStudents }) {
    const [selectedMentors, setSelectedMentors] = useState([]);

    const handleChange = (event) => {
        const selectedMentors = event.target.value;
        setSelectedMentors(selectedMentors);

        // no mentors were selected
        if (selectedMentors.length === 0) {
            setFilteredStudents([]);
            return;
        }

        const studentEmails = Object.values(studentsInfo)
            .filter(student => selectedMentors.includes(student.mentor))
            .map(student => student.email);
        setFilteredStudents(studentEmails);
    };


    return (
        <FormControl fullWidth variant='filled'>
            <InputLabel
                id="mentor-multi-select-label"
            >Mentors</InputLabel>
            <Select
                labelId="mentor-multi-select-label"
                id="mentor-multi-select"
                multiple
                value={selectedMentors}
                onChange={handleChange}
            >
                {mentors.map((mentor) => (
                    <MenuItem key={mentor} value={mentor}>
                        {mentor}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
