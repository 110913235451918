export function colorForName(name, brightness = 1.0) {
  if (name.length < 3) {
    return "rgba(12, 34, 56, 1.0)";
  }
  // regex for removing letters which are not a-zA-Z
  name = name.replace(/[^a-zA-Z]/g, "");
  const h1 = numberForLetter(name.charAt(0));
  const h2 = numberForLetter(name.charAt(1));

  const mixedH = ((h1 + h2) * 1.1) % 360;

  return `hsl(${mixedH}, ${100 * (1.05 - brightness)}%, ${
    10 + brightness * 100
  }%)`; // hsl(hue, saturation, lightness)
  // Hue is a degree on the color wheel from 0 to 360.
  // Saturation is a percentage value. 0% means a shade of gray, and 100% is the full color.
  // Lightness is also a percentage. 0% is black, 50% is neither light or dark, 100% is white
}

// this takes a letter and maps it to 0 to 360 based on its char value, so that
// 'a' maps to 0 and 'z' maps to 360
function numberForLetter(letter) {
  const upper = letter.toUpperCase();
  return Math.max(0, Math.min(360, (upper.charCodeAt(0) - 65) * 14.4));
}

// returns a color between red and green for progress between 0 and 1,
// where 1 is green and 0 is red. Color is suitable for background of black text
export function colorForProgress(progress) {
  const hue = progress * 120;
  return hue > 0 ? `hsl(${hue}, 90%, 80%)` : "white";
}

export function unitRangeToDisplayPercentage(unit) {
  if (unit === 0) {
    return "";
  }
  return Math.floor(unit * 100) + "%";
}

export function codioGrade(grade) {
  if (!grade) {
    return "V";
  }
  return `grade: ${100 * grade}`;
}
