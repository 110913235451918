// firebaseConfig.js

import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyAMhCnTidrBmv_scrV2DwfYcm7ozDgAiL0",
    authDomain: "students-dashboard-9d0c6.firebaseapp.com",
    projectId: "students-dashboard-9d0c6",
    storageBucket: "students-dashboard-9d0c6.appspot.com",
    messagingSenderId: "748420079756",
    appId: "1:748420079756:web:8e40f7e44cd2be24a21ba9",
    measurementId: "G-HLXQQKG10J"
};

const app = initializeApp(firebaseConfig);

export default app;
