import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Tooltip } from '@mui/material';

import { styled } from '@mui/material/styles';

const getColorForValue = (value) => {
  switch (value) {
    case 1:
      return 'red';
    case 2:
      return '#ff6666'; // Lighter shade of red
    case 3:
      return '#ffec48'; // Yellow
    case 4:
      return '#c4ffd3'; // Very light green
    case 5:
      return '#6fff93'; // Dark green
    default:
      return 'white'; // fallback
  }
};

const BlinkingDiv = styled('div')`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: ${props => (props.content === '' ? 'grey' : 'black')};
  background-color: ${props => getColorForValue(props.content)};
  animation: ${props => (props.hasNote ? 'blink 1s infinite' : 'none')};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${props => (props.hasNote ? 'bold' : 'normal')};
  @keyframes blink {
    0%, 100% { 
      transform: scale(1);
    }
    50% { 
      transform: scale(1.2);
    }
  }
`;


const Heatmap = ({ data }) => {
  // Helper function to get the weekday from a date string
  const getWeekdayFromDate = (dateString) => {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const date = new Date(dateString);
    return daysOfWeek[date.getUTCDay()];
  };

  // Get weekdays from the data, assume all of the students have the same set of dates
  const dateRange = data.date_range;
  const uniqueDaysOfWeek = dateRange.map(dateString => getWeekdayFromDate(dateString));
  const studentsInfo = data.students_info;

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
    }}>
      <Paper elevation={3} style={{
        width: '80%',
        marginTop: "40px",
        border: 0
      }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Student</TableCell>
              {uniqueDaysOfWeek.map(day => (
                <TableCell key={day} style={{ textAlign: "center", fontWeight: "bold" }}>
                  <div style={{
                    width: '50px',
                    height: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>{day}</div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(studentsInfo).map(([studentName, motivationPerDate]) => (
              <TableRow key={studentName}>
                <TableCell>{studentName}</TableCell>
                {dateRange.map((date, index) => (
                  <TableCell key={index}>
                    <Tooltip title={motivationPerDate[date]?.note || ''} placement="top">
                      <BlinkingDiv
                        hasNote={Boolean(motivationPerDate[date]?.note)}
                        content={motivationPerDate[date]?.rating || ''}
                      >
                        {motivationPerDate[date]?.rating}
                      </BlinkingDiv>
                    </Tooltip>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default Heatmap;
