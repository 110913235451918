import React, { useEffect, useState, useCallback } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import app from './firebaseConfig';
import UserContext from './UserContext';

function AuthProvider({ children }) {
    const [isLoadingAuth, setIsLoadingAuth] = useState(true);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const auth = getAuth(app);
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
            } else {
                setUser(null);
            }
            setIsLoadingAuth(false);
        });

        // Cleanup observer on unmount
        return () => unsubscribe();
    }, []);

    const authenticatedFetch = useCallback(async (url, options = {}) => {
        if (!user) {
            return;
        }

        const token = await user.getIdToken();
        const headers = {
            ...options.headers,
            'Authorization': `Bearer ${token}`
        };

        const response = await fetch(url, {
            ...options,
            headers
        });

        if (!response.ok) {
            throw new Error(`API request failed with status ${response.status}`);
        }

        return response;
    }, [user]);

    return (
        <UserContext.Provider value={{ user, isLoadingAuth, authenticatedFetch }}>
            {children}
        </UserContext.Provider>
    );
}

export default AuthProvider;
