// components/PrivateRoute/PrivateRoute.jsx

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import { Outlet } from 'react-router-dom';

function PrivateRoute() {
    const {user, isLoadingAuth} = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoadingAuth && !user) {
            navigate("/login");
        }
    }, [user, isLoadingAuth, navigate]);

    return <Outlet />; // The child routes will be rendered here
}

export default PrivateRoute;
