import { createContext, useContext } from 'react';

const UserContext = createContext({
    user: null,
    isLoadingAuth: null,
    authenticatedFetch: () => Promise.resolve(),
});

export function useUser() {
    return useContext(UserContext);
}

export default UserContext;
