import React, { useEffect, useState } from "react";
import Heatmap from "./Heatmap";
import { useUser } from "../Auth/UserContext";
import { Box, CircularProgress, Snackbar, Typography } from "@mui/material";
import { useSettings } from "../Layout/SettingsContext";

const LiveLessons = () => {
  const [loading, setLoading] = useState(true);
  const { authenticatedFetch } = useUser();
  const [studentsData, setStudentsData] = useState({});
  const { selectedOffering } = useSettings();
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetching data from the API endpoint
    const apiHost = process.env.REACT_APP_HOST || "";
    setLoading(true);
    authenticatedFetch(
      `${apiHost}/api/attendance?offeringId=${selectedOffering.id}`
    )
      .then((response) => {
        if (!response) {
          return {};
        }
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setStudentsData(data);
      })
      .catch((error) => {
        console.error("There was a problem fetching the data:", error)
        setError(error.message)
        setStudentsData({})
      }
      )
      .finally(() => {
        setLoading(false);
      });
  }, [authenticatedFetch, selectedOffering.id]);

  return (
    <Box height="100%" width="100%">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={error !== null}
        autoHideDuration={4000}
        onClose={() => setError(null)}
        ContentProps={{
          sx: {
            bgcolor: "error.main",
          },
        }}
        message={
          <Typography variant="body2">Failed fetching data</Typography>
        }
      />
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          zIndex: loading ? 100 : -1,
          opacity: loading ? 100 : 0,
          position: "absolute",
          backgroundColor: "white",
          transition: "all 0.5s ease-in-out",
        }}
      >
        <CircularProgress />
      </Box>

      {Object.keys(studentsData).length > 0 ? (
        <Heatmap data={studentsData} />
      ) : <Box width="100%" height="100%" display="flex"
              justifyContent="center" alignItems="center">
        <Typography>{loading ? '' : "No data"}</Typography>
      </Box>}
    </Box>
  );
};

export default LiveLessons;
