import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Tooltip } from '@mui/material';

const possibleTimesInDay = ['Morning', 'Afternoon'];


const Heatmap = ({ data }) => {
  // Helper function to get the weekday from a date string
  const getWeekdayFromDate = (dateString) => {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const date = new Date(dateString);
    return daysOfWeek[date.getUTCDay()];
  };

  // Get weekdays from the data, assume all of the students have the same set of dates
  const uniqueDaysOfWeek = Object.keys(Object.values(data)[0]).map(dateString => getWeekdayFromDate(dateString));

  // Convert the object to an array and sort by sum of attended
  const sortedAttendance = Object.fromEntries(
    Object.entries(data).sort((a, b) => {
      const totalSessionsA = a[1] ? Object.values(a[1]).flat().length : 0;
      const totalSessionsB = b[1] ? Object.values(b[1]).flat().length : 0;
      return totalSessionsB - totalSessionsA; // To sort in descending order
    })
  );

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
    }}>
      <Paper elevation={3} style={{
        width: '80%',
        marginTop: "40px",
        border: 0
      }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Student</TableCell>
              {uniqueDaysOfWeek.map(day => (
                <TableCell key={day} style={{ textAlign: "center", fontWeight: "bold" }}>
                  <div style={{
                    width: '50px',
                    height: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    margin: 'auto'
                  }}>{day}</div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(sortedAttendance).map(([studentName, days]) => (
              <TableRow key={studentName}>
                <TableCell>{studentName}</TableCell>
                {
                  Object.values(days).map((sessions, index) => (
                    <TableCell key={index}>
                      <div style={{
                        display: "flex",
                        justifyContent: "center"
                      }}>
                        {possibleTimesInDay.map(timeInDay => (
                          <Tooltip title={timeInDay} placement="top" key={timeInDay}>
                            <div style={{
                              backgroundColor: sessions.includes(timeInDay) ? '#ff73e3' : '#e4e4e4',
                              color: 'white',
                              width: '15px',
                              height: '15px',
                              borderRadius: "2px",
                              marginLeft: '1px'
                            }}>
                            </div>
                          </Tooltip>
                        ))}
                      </div>
                    </TableCell>
                  ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div >
  );
};

export default Heatmap;
