import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export function SprintFilter({
  sprints,
  setFilteredSprints,
}) {
  const [selectedSprints, setSelectedSprints] = useState(JSON.parse(window.localStorage.getItem('selectedSprints')) ?? []);
  const [selectedUnits, setSelectedUnits] = useState(JSON.parse(window.localStorage.getItem('selectedUnits')) ?? []);

  useEffect(() => {
    if (selectedUnits.length === 0) {
      setSelectedSprints([]);
    }
  }, [selectedUnits]);


  useEffect(() => {
    if (selectedSprints.length > 0) {
      setFilteredSprints(selectedSprints);
      return;
    }

    // if we selected a unit, filter all the sprints
    if (selectedUnits.length > 0) {
      const filteredSprints = sprints.filter((sprint) =>
        selectedUnits.includes(sprint.unit_id)
      );
      setFilteredSprints(filteredSprints.map(sprint => sprint.id));
      return;
    }

    // this case happens when no sprints or units were selected
    setFilteredSprints(sprints.map(sprint => sprint.id));
  }, [selectedSprints, selectedUnits, sprints, setFilteredSprints]);

  const onUnitSelectChange = (event) => {
    const newSelectedUnits = event.target.value;
    setSelectedUnits(newSelectedUnits);
    window.localStorage.setItem('selectedUnits', JSON.stringify(newSelectedUnits));
    window.localStorage.setItem('selectedSprints', JSON.stringify([]));
  };

  const onSprintSelectChange = (event) => {
    const newSelectedSprints = event.target.value;
    setSelectedSprints(newSelectedSprints);
    window.localStorage.setItem('selectedSprints', JSON.stringify(newSelectedSprints));
  };

  const units = [];
  sprints.forEach((sprint) => {
    if (!units.some((unit) => unit.id === sprint.unit_id)) {
      units.push({ id: sprint.unit_id, name: sprint.unit_name });
    }
  });
  const filteredSprints = selectedUnits.length === 0 ? sprints : sprints.filter((sprint) =>
    selectedUnits.includes(sprint.unit_id)
  );

  return (
    <div>
      <FormControl fullWidth variant="filled">
        <InputLabel id="unit-multi-select-label">Units</InputLabel>
        <Select
          labelId="unit-multi-select-label"
          id="unit-multi-select"
          multiple
          value={selectedUnits}
          onChange={onUnitSelectChange}
        >
          {Array.from(units).map((unit, index) => (
            <MenuItem key={String(unit.id)} value={unit.id}>
              Unit {unit.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth variant="filled">
        <InputLabel id="sprint-multi-select-label">Sprints</InputLabel>
        <Select
          labelId="sprint-multi-select-label"
          id="sprint-multi-select"
          multiple
          value={selectedSprints}
          onChange={onSprintSelectChange}
        >
          {filteredSprints.map((sprint, index) => (
            <MenuItem
              key={String(sprint.unit_id) + sprint.id}
              value={sprint.id}
              as="span"
            >
              <b>({sprint.unit_id})  </b> {sprintDisplayName(sprint)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

function sprintDisplayName(sprint) {
  return ' ' + sprint.id.split('.').pop().replace('sprint', '') + '. ' + sprint.name;
}